<template>
  <div>
    <AllUserFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div :class="$style.minHieght80" class="main-content">
      <template v-if="isLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isLoading && noRecord">
        <NoRecordFound />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH
              v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
              v-show="
                showColumnInDataTable(filtersData, head.dataTableName, ['Name', 'Phone', 'Email'])
              "
              :key="head.id"
            >
              {{ head.dataTableName }}
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(user, index) in userList" :key="index">
            <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
              <SingleUserDisplay :user="user" label="full_name" :image="true" />
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Role')">
              <BadgeDisplay :options="user.role_with_levels" label="title" :image="true" />
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Phone', ['Phone'])">
              <DisplayPhoneNumber :phone="user.phone" />
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
              {{ user.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
              {{ user.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
              {{
                user.profile && user.profile.date_of_birth
                  ? user.profile.date_of_birth
                  : GENERAL_CONSTANTS.NOT_APPLICABLE || GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Address')">
              {{ (user.profile && user.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Roles & Access')">
              <span
                class="text-primary-purple-600 cursor-pointer"
                @click="redirectToRollsAndAccessManagement(user, 'all-users')"
              >
                Roles & Access
              </span>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Status')">
              <UiStatus
                :title="
                  user.login_activated_at
                    ? GENERAL_CONSTANTS.LOGIN_ENABLED
                    : GENERAL_CONSTANTS.LOGIN_DISABLED
                "
                :success="!!user.login_activated_at"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
    </div>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(userCounts, filteredRecordLimit)"
      @filterRecord="getAllUsers"
    />
  </div>
</template>

<script>
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import AllUserFilter from '@src/router/views/all-users/AllUserFilter.vue'
import generalMixins from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { mapState, mapActions } from 'vuex'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { transformTextToCapitalize } from '@src/utils/generalUtil.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  objectDeepCopy,
  redirectToRollsAndAccessManagement,
  showColumnInDataTable,
} from '@/src/utils/generalUtil.js'
import fileMixins from '@/src/mixins/file-mixins'
import { allUserGraphChart, rightBarStats } from '@/src/router/views/all-users/all-users.util.js'
import AllUserData from '@/src/router/views/all-users/all-users.json'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    Loader,
    BadgeDisplay,
    AllUserFilter,
    Pagination,
    DisplayPhoneNumber,
    SingleUserDisplay,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixins, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      showPagination: false,
      noRecord: false,
      userCounts: 0,
      forceRender: 0,
      filtersData: {},
      alwaysDisplayColumnsForCSV: {
        first_name: 'First Name',
        last_name: 'Last Name',
      },
      filteredRecordLimit: 10,
      tableHeaders: AllUserData.tableHeaders,
      defaultSelectedColumns: AllUserData.defaultSelectedColumns,
      userStats: {
        section_teacher: GENERAL_CONSTANTS.EMPTY_RECORD,
        section_student: GENERAL_CONSTANTS.EMPTY_RECORD,
        staff: GENERAL_CONSTANTS.EMPTY_RECORD,
        guardian: GENERAL_CONSTANTS.EMPTY_RECORD,
        pickup_person: GENERAL_CONSTANTS.EMPTY_RECORD,
        super_admin: GENERAL_CONSTANTS.EMPTY_RECORD,
        campus_admin: GENERAL_CONSTANTS.EMPTY_RECORD,
      },
    }
  },
  page: {
    title: 'Users',
  },
  computed: {
    ...mapState({
      userList: (state) => objectDeepCopy(state.staff.allUsers),
      tabRightBar: (state) => state.layout.tabRightNav,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      campusId: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    campusId: {
      deep: true,
      handler() {
        this.getAllUsers()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.getAllUsers()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.setRightBarData(this.userCounts, this.userStats)
      },
    },
  },
  mounted() {
    this.getAllUsers()
    this.setRightBarData(this.userCounts, this.userStats)
  },
  methods: {
    showColumnInDataTable,
    redirectToRollsAndAccessManagement,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getAllUsers()
      this.forceRender++
    },
    setRightBarData(total_user, roles) {
      let BAR_GRAPH_VALUE = [
        total_user || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.section_teacher || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.section_student || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.staff || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.guardian || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.pickup_person || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.super_admin || GENERAL_CONSTANTS.EMPTY_RECORD,
        roles?.campus_admin || GENERAL_CONSTANTS.EMPTY_RECORD,
      ]
      let PIE_GRAPH_VALUE = [5, 15]
      this.setRightbarContent({
        header: {
          title: 'ALLUSR',
        },
        stats: rightBarStats(),
        chartData: allUserGraphChart(BAR_GRAPH_VALUE, PIE_GRAPH_VALUE),
      })
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('staff', ['getUsers']),
    getAllUsers(range) {
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getUsers(payload)
        .then((res) => {
          this.processData(res)
          this.setStats(res.data.meta.role_count_stats)
          this.setRightBarData(this.userCounts, this.userStats)
        })
        .finally(() => {
          this.noRecord = isEmpty(this.userList)
          this.isLoading = false
        })
    },
    processData(res) {
      this.userCounts = res.data.meta.total_records
      this.showPagination = this.userCounts > GENERAL_CONSTANTS.RECORD_LIMIT
      this.userList.forEach((user) => {
        fullName(user)
        user.role_with_levels?.forEach((role) => {
          role.title = transformTextToCapitalize(role.role_id)
        })
      })
    },
    setStats(stats) {
      stats.forEach((stat) => {
        this.userStats[stat.role_id] = stat.user_count
      })
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
