import ALLUSER_DATA from '@/src/router/views/all-users/all-users.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
export const rightBarStats = () => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
    },
  ]
  return stats
}
export const allUserGraphChart = (BAR_GRAPH_VALUE, PIE_GRAPH_VALUE) => {
  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      ALLUSER_DATA.BAR_LABELS,
      BAR_GRAPH_VALUE,
      ALLUSER_DATA.BAR_TITLE,
      ALLUSER_DATA.BAR_LABELS_STRING,
    ),
    GRAPH_CONSTANTS.PIE_CHART(
      ALLUSER_DATA.PIE_LABELS,
      PIE_GRAPH_VALUE,
      ALLUSER_DATA.PIE_BACKGROUND_COLOR,
      ALLUSER_DATA.PIE_TITLE,
    ),
  ]
  return chartData
}
